<template>
    <modal
        class="delete-board"
        :adaptive="true"
        name="delete-board"
        height="auto"
        width="550"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div class="close-modal" @click="$modal.hide('delete-board')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <div class="content">
            <h1>Delete Board</h1>
            <p>Board Book will be deleted</p>
            <div class="actions ml-auto">
                <button type="button" class="btn btn-outline-primary" @click="$modal.hide('delete-board')">
                    Cancel
                </button>
                <button type="button" class="btn btn-danger ml-3" @click="deleteBoard">
                    Delete
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: "DeleteBoard",
    data() {
        return {
            boardId: null,
            boardTitle: null,
            redirectRoute: null
        };
    },
    methods: {
        beforeOpen(event) {
            if (event.params?.boardId && event.params?.boardTitle) {
                this.boardId = event.params.boardId;
                this.boardTitle = event.params.boardTitle;
            } else {
                this.$notify({
                    group: "app-notifications",
                    type: "error",
                    text: "Can't delete this board, try again later",
                    duration: 3000
                });

                event.cancel();
            }

            if (event.params?.redirectRoute) {
                this.redirectRoute = event.params?.redirectRoute;
            }
        },
        beforeClose() {
            this.boardId = null;
            this.boardTitle = null;
            this.redirectRoute = null;
        },
        deleteBoard() {
            const boardToDelete = { id: this.boardId, title: this.boardTitle }
            this.$store.dispatch("Boards/deleteBoard", { id: this.boardId, title: this.boardTitle }).then(() => {
                if (this.redirectRoute) {
                    this.$router.push(this.redirectRoute);
                }

                this.$emit("deleted-board", boardToDelete);
                this.$modal.hide("delete-board");
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.delete-board {
    z-index: 99999;

    .close-modal {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        z-index: 3;

        @media(max-width: $sm) {
            right: 15px;
            top: 15px;

            img {
                width: 15px;
            }
        }
    }

    /deep/ .vm--modal{
        background-color: #3A3A3C;
        border-radius: 10px;
        box-shadow: none;

        .content {
            padding: 25px 20px;
            display: flex;
            flex-direction: column;

            h1 {
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
            }

            p {
                margin-top: 35px;
                margin-bottom: 45px;
                font-size: 18px;
                color: rgba(235, 235, 245, 0.6);
                text-align: center;
            }
        }
    }
}
</style>
